.comp-title {
  font-size: 23px;
  font-weight: 600;
  line-height: 29.9px;
  color: #151819;
  text-transform: capitalize;
}

.card-wrapper {
  border: 1px solid #DCDCDC;
  margin-top: 24px;
  overflow: hidden;
  border-radius: 8px;
}

.comp-container {
  display: flex;
  justify-content: center;
  padding: 42px;
  border-bottom: 1px solid #DCDCDC;
}

.code-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #F3F7F9;
  border-radius: 8px;
}

.code-title {
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 8px;
}

.copy-btn {
  position: absolute;
  top: 28px;
  right: 24px;
  padding: 6px;
  background-color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color .4s;

  &:hover {
    background-color: #939CA2;

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
}

.footer-text {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 12px;
}

.link-info {
  color: #596066;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
}

.footer-link {
  cursor: pointer;
  text-decoration: underline;
  color: #151819;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8px;

  &:hover {
    text-decoration: none;
  }
}