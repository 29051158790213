.delivery-error-rate-tooltip {
  background-color: var(--emt-background-info);
  border-radius: var(--emt-border-radius-200);
  box-shadow: 0px 6px 14px 0px #0807071a;
  color: #1f2229;
  padding: var(--emt-space-300) var(--emt-space-400) var(--emt-space-300)
    var(--emt-space-300);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: var(--emt-background-info) transparent transparent transparent;
  }

  &.tooltip-right {
    &:before {
      left: auto;
      right: 18px;
      margin-left: 0;
      margin-right: -6px;
    }
  }

  table.tooltip-table {
    margin: 0;
  }

  thead {
    tr {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: var(--emt-space-50);
      }

      th {
        font-size: var(--emt-font-size-100);
        font-weight: var(--emt-font-weight-regular);
        line-height: var(--emt-line-height-120);
        color: var(--emt-text-on-bg-secondary);
        opacity: 0.7;
        text-align: left;
      }
    }
  }

  tbody {
    &:before {
      content: '';
      display: block;
      line-height: 2px;
      color: transparent;
    }

    tr {
      td {
        font-size: var(--emt-font-size-200);
        font-weight: var(--emt-font-weight-regular);
        line-height: var(--emt-line-height-150);
        text-align: left;
        color: var(--emt-text-on-bg-fill);

        span {
          font-weight: var(--emt-font-weight-medium);
          margin-left: 4px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
}
