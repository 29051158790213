.mail-date-picker {

  // input box
  .react-date-picker__wrapper {
    padding: var(--emt-space-250) var(--emt-space-300);
    background-color: var(--emt-background-surface);
    border-color: var(--emt-border-input);
    border-radius: var(--emt-border-radius-100);
    cursor: pointer;
    transition: border .4s;

    &:hover {
      border-color: var(--emt-border-input-active);
    }
  }

  .react-date-picker__inputGroup,
  .react-date-picker__calendar-button {
    padding: 0;
  }

  .react-date-picker__clear-button {
    display: none;
  }

  .react-date-picker__button {
    margin-left: var(--emt-space-200);
  }

  input,
  .react-date-picker__inputGroup__divider,
  .react-date-picker__inputGroup__leadingZero {
    color: var(--emt-text-primary);
    font-size: var(--emt-font-size-300);
    font-weight: var(--emt-font-weight-regular);
    line-height: var(--emt-line-height-120);
    cursor: pointer;
  }

  &.mail-date-picker-active {
    .react-date-picker__wrapper {
      border-color: var(--emt-border-input-active);
    }
  }




  // popup
  .react-date-picker__calendar {
    min-width: 312px;
    width: auto;
  }

  .react-calendar {
    background-color: var(--emt-background-surface);
    padding: var(--emt-space-400);
    box-shadow: var(--emt-shadow-400);
    border: none;
    width: auto;
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--emt-space-500);
    height: 24px;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__arrow {
    height: var(--emt-space-600);
    min-width: var(--emt-space-600);
    font-size: var(--emt-space-600);
  }

  .react-calendar__navigation__label__labelText {
    font-weight: var(--emt-font-weight-medium);
    font-size: var(--emt-font-size-300);
    line-height: var(--emt-line-height-120);
    color: var(--emt-text-primary);
    letter-spacing: var(--emt-font-letter-spacing-normal);
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 11px;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0;
    width: var(--emt-space-1000);
    font-weight: var(--emt-font-weight-semi-bold);
    font-size: var(--emt-font-size-100);
    line-height: var(--emt-line-height-120);
    letter-spacing: var(--emt-font-letter-spacing-normal);
    color: #6B778C;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: var(--emt-text-placeholder) !important;
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--emt-background-surface);
    padding: var(--emt-space-150) var(--emt-space-250);
    font-family: var(--emt-font-family-primary) !important;
    font-weight: var(--emt-font-weight-medium);
    font-size: var(--emt-font-size-300);
    line-height: var(--emt-line-height-120);
    letter-spacing: var(--emt-font-letter-spacing-normal);
    height: var(--emt-space-800);
    max-width: var(--emt-space-1000);
    color: var(--emt-text-secondary);

    &:hover {
      background-color: var(--emt-background-surface-hover);
    }

    &.react-calendar__tile--now {
      abbr {
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--emt-space-500);
        height: var(--emt-space-500);
        border-radius: var(--emt-border-radius-full);
        background-color: var(--emt-background-fill-selected);
        color: var(--emt-text-on-bg-fill);
      }

      &:hover {
        background-color: var(--emt-background-surface-hover);
      }
    }
  }

  .react-calendar__tile--active {
    background-color: var(--emt-background-fill-secondary-selected) !important;

    &:hover {
      background-color: var(--emt-background-fill-secondary-selected) !important;
    }
  }

  .react-calendar__tile.react-calendar__year-view__months__month,
  .react-calendar__decade-view__years__year,
  .react-calendar__century-view__decades__decade {
    padding: var(--emt-space-200);
    font-weight: var(--emt-font-weight-medium);
    font-size: var(--emt-font-size-300);
    line-height: var(--emt-line-height-120);
    letter-spacing: var(--emt-font-letter-spacing-normal);

    &:hover {
      background-color: var(--emt-background-surface-hover);
    }

    &.react-calendar__tile--now {
      background-color: var(--emt-background-fill-selected);
      color: var(--emt-text-on-bg-fill);
    }
  }

  .react-calendar__tile {
    text-wrap: nowrap;
  }
}