.mail-select {
  &[data-has-value="true"] {
    [data-slot="value"] {
      color: var(--emt-text-primary) !important;
    }
  }

  [data-slot="trigger"] {
    &[data-open="true"] {
      border-color: var(--emt-border-input-active)
    }
  }
}

.mail-select-popover {

  [data-slot="listbox"] {
    gap: 0;

    li {
      padding: var(--emt-space-200) var(--emt-space-250);
      gap: var(--emt-space-200);
      border-radius: var(--emt-border-radius-150);
      outline: none !important;

      span {
        color: var(--emt-text-primary);
        font-weight: var(--emt-font-weight-medium);
        font-size: var(--emt-font-size-300);
        line-height: 21px;
      }

      &[data-selected="true"],
      &[data-hover="true"],
      &[data-focus="true"] {
        background-color: var(--emt-background-surface-hover);
      }
    }
  }
}