.mail-dropdown-list {
  li {
    display: flex;
    align-items: center;
    gap: var(--emt-space-200);
    padding: var(--emt-space-200) var(--emt-space-250);
    border-radius: var(--emt-border-radius-150);
    outline: none !important;

    span {
      color: var(--emt-text-primary);
      font-weight: var(--emt-font-weight-medium);
      font-size: var(--emt-font-size-300);
    }

    &:hover {
      background-color: var(--emt-background-surface-hover);
    }
  }
}