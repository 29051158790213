.textarea-chips {
  textarea {
    outline: unset !important;
  }

  [data-slot='input-wrapper'] {
    min-height: auto;
    height: auto;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    padding: 0;
    box-shadow: none !important;

    &:focus-within {
      background-color: transparent !important;
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  [data-focus='true'],
  [data-focus-visible='true'] {
    outline: 0;
  }
}

.tag {
  span[role='button'] {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'%3E%3Cpath d='M6.66467 1.33594L1.33838 6.66047' stroke='%23363A44' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M6.66683 6.66732L1.3335 1.33398' stroke='%23363A44' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
    padding: var(--emt-space-100);
    border-radius: var(--emt-border-radius-full);
  }
}