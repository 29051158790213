.mail-pagination {
  [data-slot="item"] {
    &.group {
      border: none;
      box-shadow: none;

      &:hover {
        background-color: transparent;
      }
    }

    &[data-hover="true"] {
      background-color: var(--emt-background-surface-hover) !important;
    }
  }

  &-info {
    color: var(--emt-text-tertiary);
    font-size: var(--emt-font-size-50);
  }

  &-count {}

  &-count-text {
    font-size: var(--emt-font-size-200);
  }
}