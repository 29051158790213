@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/css/product-tokens/variables.css';

@layer base {
    * {
        font-family: 'Manrope', sans-serif !important;
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0) light;
    }
}

@layer components {
    .section-title {
        @apply text-[32px] leading-[44.2px] font-semibold text-[#151819] capitalize;
    }

    .comp-desc {
        @apply text-[16px] leading-[22.2px] font-normal text-[#596066] mt-3;
    }
}