.mail-table {
  &-head {
    tr {
      &:last-child {
        height: auto;
        margin-top: 0 !important;
      }
    }
  }

  &-body {
    border-radius: 0;

    td {
      padding: var(--emt-space-400) var(--emt-space-600);

      &::before {
        background-color: var(--emt-background-surface-hover);
        border-radius: 0 !important;
      }
    }
  }
}
