.custom-steps {
  .step-item {
    &.active {
      .step-number {
        border-color: var(--emt-background-surface);
        background-color: var(--emt-border-border-inverse);
        color: var(--emt-text-primary);
      }
    }

    &.done {
      .step-number {
        background-color: var(--emt-background-surface);
      }
    }

    &[aria-pressed='true'] {
      .step-title {
        color: var(--emt-text-on-bg-fill);
      }
    }

    &:not(:last-child):after {
      background-color: var(--emt-primary-500);
      border-color: var(--emt-primary-500);
    }

    &:last-child:after {
      border: 0;
    }
  }
}
