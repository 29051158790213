.mail-radio {
  &:hover {
    .mail-radio-wrapper {
      border-color: var(--emt-border-selected);
      background-color: transparent !important;
    }
  }

  &-wrapper {
    box-shadow: none !important;

    span {
      display: block;
      width: 12px;
      height: 12px;
      background-color: var(--emt-border-surface);
    }
  }

  &[data-selected="true"] {
    .mail-radio-wrapper {
      border-color: var(--emt-border-selected);
      border-width: var(--emt-space-150);
    }
  }

  &.disabled {
    pointer-events: none;

    &[data-selected="true"] {
      .mail-radio-wrapper {
        border-color: var(--emt-background-disabled) !important;
      }
    }
  }
}