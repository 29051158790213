.mail-checkbox {
  .mail-checkbox-wrapper {
    box-shadow: none !important;
  }

  &[data-hover="true"] {
    .mail-checkbox-wrapper {
      &::before {
        border-color: var(--emt-border-selected);
        background-color: transparent !important;
      }
    }
  }

  &.mail-checkbox-error {
    &[data-hover="true"] {
      .mail-checkbox-wrapper {
        &::before {
          border-color: var(--emt-text-negative);
        }
      }
    }
  }
}