// .organization-dropdown {
//   &[aria-expanded="true"] {
//     opacity: 1;
//   }
// }

.link-container {
  .icon-container {
    svg {
      width: var(--emt-space-400);
      height: var(--emt-space-400);

      path,
      rect {
        transition: stroke .4s;
        stroke: var(--emt-background-inverse);
      }
    }

    &.disabled {
      svg {

        path,
        rect {
          stroke: var(--emt-text-button-stroke-disabled);
        }
      }
    }
  }

  &:hover {
    .icon-container {
      svg {

        path,
        rect {
          stroke: var(--emt-background-surface);
        }
      }
    }
  }

  &.active {
    .icon-container {
      svg {

        path,
        rect {
          stroke: var(--emt-background-surface);
        }
      }
    }

  }
}