.mail-tree {
  .rct-node-parent {
    border-radius: var(--emt-border-radius-150);
    padding: 0 var(--emt-space-200);

    .rct-checkbox {
      padding-right: 0;
    }

    .rct-text {
      gap: var(--emt-space-300);
      padding: var(--emt-space-300) 0;
      border-bottom: 1px solid var(--emt-border-primary);

      label {
        &:hover {
          background-color: transparent;
        }
      }
    }

    .rct-title {
      font-weight: var(--emt-font-weight-bold);
      font-size: var(--emt-font-size-300);
      color: var(--emt-text-primary);
      text-transform: capitalize;
      padding: 0;
      margin-left: var(--emt-space-150);
    }

    .rct-collapse {
      padding: 0;
      height: var(--emt-space-500);

      svg {
        width: var(--emt-space-500);
        height: var(--emt-space-500);
      }
    }
  }

  .rct-node {
    .rct-text {
      label {
        display: flex;
        align-items: center;
      }
    }
  }

  .rct-node-leaf {
    padding: var(--emt-space-300) 0;

    .rct-collapse {
      display: none;
    }

    .rct-title {
      margin-left: var(--emt-space-200);

    }

    .rct-text {
      padding: 0;
      border-bottom: none;
      gap: 0;
    }

    .rct-title {
      font-weight: var(--emt-font-weight-medium);
      font-size: var(--emt-font-size-300);
      color: var(--emt-text-primary);

    }
  }

  .rct-node-icon {
    display: none;
  }

  .mail-checkbox {
    padding: 0;
  }

  .react-checkbox-tree ol ol {
    padding-left: 32px;
  }

  .rct-text {

    /* CheckBox */
    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: var(--emt-space-400);
      height: var(--emt-space-400);
      border: var(--emt-border-width-150) solid var(--emt-border-tertiary);
      border-radius: var(--emt-border-radius-100);
      outline: none;
      background-color: transparent;
      cursor: pointer;
      position: relative;
      transition: all 0.2s ease;
      margin: 0;

      &:hover {
        border-color: var(--emt-background-fill-selected);
      }
    }

    input[type="checkbox"]::after {
      content: '';
      width: 9px;
      height: 9px;
      background: url('data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%228%22%20viewBox%3D%220%200%208%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1.33337%203.83333L3.22279%205.66667L7.00004%202%22%20stroke%3D%22white%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E') no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }

    input[type="checkbox"]:checked {
      border-color: var(--emt-background-fill-selected);
      background-color: var(--emt-background-fill-selected);
    }

    input[type="checkbox"]:checked::after {
      display: block;
    }

    input[type="checkbox"]:indeterminate {
      border-color: var(--emt-background-fill-selected);
      background-color: var(--emt-background-fill-selected);
    }

    input[type="checkbox"]:indeterminate::after {
      content: '';
      width: var(--emt-space-200);
      height: var(--emt-space-50);
      background-color: var(--emt-background-surface);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
    }
  }
}