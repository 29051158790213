.user-report-tooltip {
  background-color: var(--emt-background-surface);
  border-radius: var(--emt-border-radius-200);
  box-shadow: 0px 6px 14px 0px #0807071a;
  padding: var(--emt-space-300) var(--emt-space-400);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 18px;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: var(--emt-background-surface) transparent transparent
      transparent;
  }

  &.tooltip-right {
    &:before {
      left: auto;
      right: 18px;
      margin-left: 0;
      margin-right: -6px;
    }
  }

  table.tooltip-table {
    display: flex;
    flex-direction: column;
    gap: var(--emt-space-200);
    margin: 0;
    margin-bottom: var(--emt-space-400);
  }

  thead {
    tr {
      display: flex;
      align-items: center;
      gap: var(--emt-space-200);

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      th {
        font-size: var(--emt-font-size-300);
        font-weight: var(--emt-font-weight-bold);
        line-height: var(--emt-line-height-120);
        color: var(--emt-text-secondary);
        opacity: 0.7;
      }

      span {
        font-size: var(--emt-font-size-100);
        font-weight: var(--emt-font-weight-regular);
        line-height: var(--emt-line-height-120);
        color: var(--emt-text-tertiary);
        opacity: 0.7;
      }
    }
  }

  tbody {
    &:before {
      content: '';
      display: block;
      line-height: 0.5rem;
      color: transparent;
    }

    tr {
      td {
        color: var(--emt-text-primary);
        font-weight: var(--emt-font-weight-medium);
        font-size: var(--emt-font-size-200);
        line-height: var(--emt-line-height-120);
      }

      td,
      span {
        color: var(--emt-text-primary);
        font-weight: var(--emt-font-weight-semi-bold);
        font-size: var(--emt-font-size-200);
        line-height: var(--emt-line-height-120);
        text-wrap: nowrap;
      }

      span {
        font-weight: var(--emt-font-weight-semi-bold);
        margin-left: 4px;
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
}

.textarea-container {
  position: relative;

  .tooltip-comment {
    background-color: var(--emt-background-secondary);
    border-radius: var(--emt-border-radius-200);
    padding: var(--emt-space-200) var(--emt-space-300);
    resize: none;
    height: 2rem;
    font-size: var(--emt-space-300);
    font-weight: var(--emt-font-weight-regular);
    line-height: var(--emt-line-height-120);

    &::placeholder {
      color: var(--emt-text-primary);
    }

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: var(--emt-text-tertiary);
      font-weight: var(--emt-font-weight-regular);
      font-size: var(--emt-font-size-100);
    }
  }

  &.expand {
    .tooltip-comment {
      height: 4rem;
      padding: var(--emt-space-100) var(--emt-space-200) var(--emt-space-100)
        var(--emt-space-300);
    }
  }

  .send-button {
    position: absolute;
    width: 18.75px;
    height: 18.75px;
    background-color: var(--emt-background-tertiary);
    border-radius: var(--emt-border-radius-full);
    display: grid;
    place-items: center;
    margin: 2.62px;
    top: 4px;
    right: 8px;
  }

  &.expand {
    .send-button {
      top: initial;
      bottom: 8px;
      background-color: var(--emt-background-brand);
    }
  }
}
