.mail-input {
  &[data-focus="true"] {
    [data-slot="input-wrapper"] {
      background-color: transparent !important;
      border-color: var(--emt-border-input-active);
      box-shadow: none !important;
    }
  }

  &[data-hover="true"] {
    [data-slot="input-wrapper"] {
      background-color: transparent;
    }
  }
}