.main-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.navbar {
  background-color: #f4f6fb;
  padding: 24px 0 24px 40px;
  position: fixed;
  height: 100%;
  min-width: 250px;
}

.component-text {
  color: #939ca2;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  margin-top: 24px;
  text-transform: capitalize;
}

.link-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  overflow-y: auto;
  max-height: 87%;
}

.nav-link {
  color: #596066;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  transition: color 0.4s;
  text-transform: capitalize;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #34647f;
  }

  &.active {
    color: #34647f;
  }
}

.main-content {
  min-width: 0;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  margin-left: 250px;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 48px 28px 20px 40px;
  margin-bottom: 10px;
  border-bottom: 1px solid #596066;
  background-color: #ffffff;
}

.component-title {
  font-size: 48px;
  color: #000000;
  font-weight: 700;
  line-height: 48px;
  text-transform: capitalize;
}

.content-section {
  min-width: 0;
  flex: 1 1 0;
  padding: 10px 28px 48px 40px;
}