.mail-search {
  .input-wrapper {
    box-shadow: none !important;

    [data-slot="input"] {
      color: var(--emt-text-primary);
      font-weight: var(--emt-font-weight-regular);
      font-size: var(--emt-font-size-300);
      line-height: var(--emt-line-height-120);

      &::placeholder {
        color: var(--emt-text-placeholder);
        font-weight: var(--emt-font-weight-regular);
        font-size: var(--emt-font-size-300);
        line-height: var(--emt-line-height-120);
      }
    }

    &[data-focus="true"] {
      border-color: var(--emt-border-input-active);
    }
  }
}