.mail-tab {
  .mail-tabList {
    border-bottom: var(--emt-space-25) solid var(--emt-border-primary);

    [data-slot="tab"] {
      border-bottom: var(--emt-space-50) solid transparent;
      transition: all 0.4s;

      &:hover {
        border-color: var(--emt-background-fill-selected);
        opacity: 1;

        [data-slot="tabContent"] {
          color: var(--emt-text-primary);
        }
      }

      &[data-selected="true"] {

        [data-slot="tabContent"] {
          color: var(--emt-text-primary);
        }
      }
    }
  }
}