/**
 * Do not edit directly
 * Generated on Thu, 30 May 2024 05:49:22 GMT
 */

:root {
  --emt-background-button-fill-primary: #0F5FCC;
  --emt-background-button-fill-hovered: #0E56BA;
  --emt-background-button-fill-pressed: #0E56BA;
  --emt-background-button-fill-disabled: #0f5fcc99;
  --emt-background-button-stroke-primary: #0c0d1099;
  --emt-background-button-stroke-hovered: #0c0d10b3;
  --emt-background-button-stroke-pressed: #0c0d10b3;
  --emt-background-button-stroke-disabled: #0c0d1040;
  --emt-background-button-danger-primary: #D14D52;
  --emt-background-button-danger-hovered: #C62127;
  --emt-background-button-danger-pressed: #C62127;
  --emt-background-button-danger-disabled: #0c0d1040;
  --emt-background-button-default-primary: #FAFBFC;
  --emt-background-button-default-hovered: #E7EFFA;
  --emt-background-button-default-pressed: #E7EFFA;
  --emt-background-button-default-disabled: #0c0d1014;
  --emt-background-button-ghost-primary: #0F5FCC;
  --emt-background-button-ghost-hovered: #FAFBFC;
  --emt-background-button-ghost-pressed: #FAFBFC;
  --emt-background-button-ghost-icon-fill: #0F5FCC;
  --emt-background-button-dark-fill-fill: #ffffff;
  --emt-background-button-dark-fill-hovered: #F6F8FA;
  --emt-background-button-dark-fill-pressed: #F1F4F8;
  --emt-background-button-dark-fill-disabled: #0c0d1014;
  --emt-background-button-dark-fill-stroke: #ffffff;
  --emt-background-surface: #ffffff;
  --emt-background-surface-hover: #F6F8FA;
  --emt-background-surface-transparent: #ffffff00;
  --emt-background-negative: #C62127;
  --emt-background-disabled: #0c0d1014;
  --emt-background-caution: #B2801C;
  --emt-background-informative: #3065ad;
  --emt-background-inverse: #0C0D10;
  --emt-background-primary: #FAFBFC;
  --emt-background-secondary: #F1F4F8;
  --emt-background-tertiary: #D0D5E0;
  --emt-background-fill-selected: #0F5FCC;
  --emt-background-fill-secondary-hover: #0E56BA;
  --emt-background-fill-secondary-selected: #A6C6F5;
  --emt-background-on-bg-hover: #ffffff26;
  --emt-background-info: #0C0D10;
  --emt-background-notification: #8D171C;
  --emt-background-emphasis: #0E56BA;
  --emt-background-discovery: #E7EFFA;
  --emt-background-surface-secondary: #A8AEBA;
  --emt-background-surface-tertiary: #7F8592;
  --emt-background-surface-caution: #F3C15F;
  --emt-background-surface-informative: #89ABD2;
  --emt-background-surface-success: #89D2B3;
  --emt-background-surface-danger: #D28989;
  --emt-background-brand: #0F5FCC;
  --emt-background-fill-caution: #F9F3E8;
  --emt-background-fill-informative: #eaf0f7;
  --emt-background-fill-positive: #EDF8F4;
  --emt-background-fill-positive-secondary: #C8EADC;
  --emt-background-fill-positive-secondary-hover: #AEE0CB;
  --emt-background-fill-informative-secondary: #bfcfe6;
  --emt-background-fill-negative-secondary: #EDBABC;
  --emt-background-fill-negative-secondary-hover: #E5999C;
  --emt-background-fill-default: #F1F4F8;
  --emt-background-fill-default-hover: #EBEEF4;
  --emt-background-fill-transparent-hover: #ffffff80;
  --emt-background-fill-transparent-selected: #0f5fcc99;
  --emt-background-overlay: #0c0d101f;
  --emt-text-button-fill-primary: #ffffff;
  --emt-text-button-fill-disabled: #0c0d1040;
  --emt-text-button-stroke-primary: #0C0D10;
  --emt-text-button-stroke-disabled: #0c0d1040;
  --emt-text-button-default-primary: #0F5FCC;
  --emt-text-button-default-disabled: #0c0d1040;
  --emt-text-button-danger-primary: #ffffff;
  --emt-text-button-ghost-primary: #0C0D10;
  --emt-text-button-ghost-disabled: #0c0d1040;
  --emt-text-button-link-primary: #0F5FCC;
  --emt-text-button-link-hovered: #0E56BA;
  --emt-text-button-link-pressed: #0E56BA;
  --emt-text-button-link-negative: #D14D52;
  --emt-text-button-link-disabled: #0c0d1040;
  --emt-text-button-dark-fill-fill: #0C0D10;
  --emt-text-button-dark-fill-disabled: #0c0d1014;
  --emt-text-primary: #0C0D10;
  --emt-text-secondary: #2B2E37;
  --emt-text-tertiary: #5B616E;
  --emt-text-placeholder: #7F8592;
  --emt-text-negative: #C62127;
  --emt-text-caution: #B2801C;
  --emt-text-positive: #4FBC8E;
  --emt-text-informative: #3065ad;
  --emt-text-on-bg-fill: #ffffff;
  --emt-text-on-bg-fill-transperant-hover: #ffffff80;
  --emt-text-brand: #0F5FCC;
  --emt-text-on-bg-secondary: #D0D5E0;
  --emt-text-negative-secondary: #D28989;
  --emt-text-disabled: #0c0d1040;
  --emt-text-discovery: #083470;
  --emt-border-primary: #EBEEF4;
  --emt-border-secondary: #A8AEBA;
  --emt-border-tertiary: #D0D5E0;
  --emt-border-negative: #C62127;
  --emt-border-disabled: #0c0d1014;
  --emt-border-caution: #B2801C;
  --emt-border-positive: #4FBC8E;
  --emt-border-informative: #3065ad;
  --emt-border-focused: #0C0D10;
  --emt-border-selected: #0F5FCC;
  --emt-border-border-inverse: #ffffff;
  --emt-border-notification-border: #FAFBFC;
  --emt-border-positive-secondary: #AEE0CB;
  --emt-border-input: #D0D5E0;
  --emt-border-input-active: #A8AEBA;
  --emt-border-negative-secondary: #D28989;
  --emt-border-informative-secondary: #a0b8d9;
  --emt-icon-primary: #2B2E37;
  --emt-icon-caution: #A8730B;
  --emt-icon-info: #0E56BA;
  --emt-icon-positive: #48AB81;
  --emt-icon-negative: #C62127;
  --emt-icon-secondary: #7F8592;
  --emt-icon-tertiary: #EBEEF4;
  --emt-icon-surface: #ffffff;
  --emt-icon-brand: #0F5FCC;
  --emt-icon-caution-secondary: #B2801C;
  --emt-icon-disabled: #0c0d1014;
  --emt-chart-color-picker1: #48AB81;
  --emt-chart-color-picker2-secondary: #F7D491;
  --emt-chart-color-picker2: #B2801C;
  --emt-chart-color-picker3: #0F5FCC;
  --emt-chart-color-picker4: #90B3DB;
  --emt-chart-color-picker5: #3F7FD6;
  --emt-chart-color-picker6: #D14D52;
  --emt-chart-color-picker7: #A6C6F5;
  --emt-chart-color-picker8: #A6C6F5;
  --emt-chart-color-picker9: #7f5cf6;
  --emt-chart-color-picker10: #E7EFFA;
  --emt-primary-100: #FAFBFC;
  --emt-primary-200: #E7EFFA;
  --emt-primary-300: #A6C6F5;
  --emt-primary-400: #90B3DB;
  --emt-primary-500: #3F7FD6;
  --emt-primary-600: #0F5FCC;
  --emt-primary-700: #0E56BA;
  --emt-primary-800: #0B4391;
  --emt-primary-900: #083470;
  --emt-primary-1000: #062856;
  --emt-supporting-color1-100: #F9F3E8;
  --emt-supporting-color1-200: #F9E2B5;
  --emt-supporting-color1-300: #F7D491;
  --emt-supporting-color1-400: #F3C15F;
  --emt-supporting-color1-500: #DCA434;
  --emt-supporting-color1-600: #B2801C;
  --emt-supporting-color1-700: #A8730B;
  --emt-supporting-color1-800: #936409;
  --emt-supporting-color1-900: #6C4906;
  --emt-supporting-color1-1000: #4F3503;
  --emt-supporting-color2-100: #FCE9E8;
  --emt-supporting-color2-200: #EDBABC;
  --emt-supporting-color2-300: #E5999C;
  --emt-supporting-color2-400: #D96A6E;
  --emt-supporting-color2-500: #D14D52;
  --emt-supporting-color2-600: #C62127;
  --emt-supporting-color2-700: #B41E23;
  --emt-supporting-color2-800: #8D171C;
  --emt-supporting-color2-900: #6D1215;
  --emt-supporting-color2-1000: #530E10;
  --emt-neutrals-100: #ffffff;
  --emt-neutrals-200: #F6F8FA;
  --emt-neutrals-300: #F1F4F8;
  --emt-neutrals-400: #EBEEF4;
  --emt-neutrals-500: #D0D5E0;
  --emt-neutrals-600: #A8AEBA;
  --emt-neutrals-700: #7F8592;
  --emt-neutrals-800: #5B616E;
  --emt-neutrals-900: #2B2E37;
  --emt-neutrals-1000: #0C0D10;
  --emt-shadow-0: 0px 0px 0px 0px #0C0D10;
  --emt-shadow-100: 0px 16px 40px 0px #0c0d1014;
  --emt-shadow-200: 0px 5px 10px 0px #0c0d1014;
  --emt-shadow-300: 0px 2px 12px 2px #0c0d101f;
  --emt-shadow-400: 0px 5px 8px 0px #0c0d1040;
  --emt-font-family-primary: Manrope;
  --emt-line-height-120: 120%;
  --emt-line-height-150: 150%;
  --emt-font-weight-regular: 400;
  --emt-font-weight-medium: 500;
  --emt-font-weight-semi-bold: 600;
  --emt-font-weight-bold: 700;
  --emt-font-size-50: 11px;
  --emt-font-size-100: 12px;
  --emt-font-size-200: 13px;
  --emt-font-size-300: 14px;
  --emt-font-size-400: 16px;
  --emt-font-size-500: 18px;
  --emt-font-size-600: 20px;
  --emt-font-size-700: 23px;
  --emt-font-size-800: 26px;
  --emt-font-size-900: 29px;
  --emt-font-size-1000: 32px;
  --emt-font-size-1100: 36px;
  --emt-font-letter-spacing-normal: 0px;
  --emt-font-letter-spacing-dense: -0.2px;
  --emt-font-letter-spacing-denser: -0.3px;
  --emt-font-letter-spacing-densest: -0.54px;
  --emt-text-case-none: none;
  --emt-text-case-uppercase: uppercase;
  --emt-paragraph-spacing-0: 0px;
  --emt-paragraph-spacing-100: 8px;
  --emt-paragraph-spacing-150: 12px;
  --emt-paragraph-spacing-200: 16px;
  --emt-text-decoration-underline: underline;
  --emt-text-decoration-none: none;
  --emt-text-decoration-strike-through: line-through;
  --emt-border-radius-0: 0px;
  --emt-border-radius-100: 4px;
  --emt-border-radius-150: 6px;
  --emt-border-radius-200: 8px;
  --emt-border-radius-250: 10px;
  --emt-border-radius-300: 12px;
  --emt-border-radius-400: 16px;
  --emt-border-radius-500: 20px;
  --emt-border-radius-700: 28px;
  --emt-border-radius-full: 9999px;
  --emt-space-0: 0px;
  --emt-space-25: 1px;
  --emt-space-50: 2px;
  --emt-space-100: 4px;
  --emt-space-150: 6px;
  --emt-space-200: 8px;
  --emt-space-250: 10px;
  --emt-space-300: 12px;
  --emt-space-400: 16px;
  --emt-space-500: 20px;
  --emt-space-600: 24px;
  --emt-space-800: 32px;
  --emt-space-900: 36px;
  --emt-space-1000: 40px;
  --emt-space-1200: 48px;
  --emt-space-1400: 56px;
  --emt-space-1450: 58px;
  --emt-space-1600: 64px;
  --emt-space-2000: 80px;
  --emt-space-2400: 96px;
  --emt-space-2800: 112px;
  --emt-space-3200: 128px;
  --emt-space-button-extra-large: 16px 24px;
  --emt-space-button-large: 12px 20px;
  --emt-space-button-normal: 12px 16px;
  --emt-space-button-dense: 10px 16px;
  --emt-space-button-denser: 4px 8px;
  --emt-ui-text-md-semi-bold: [object Object];
  --emt-ui-text-md-medium: [object Object];
  --emt-ui-text-md-regular: [object Object];
  --emt-ui-text-sm-semi-bold: [object Object];
  --emt-ui-text-sm-medium: [object Object];
  --emt-ui-text-sm-bold: [object Object];
  --emt-ui-text-sm-regular: [object Object];
  --emt-ui-text-xs-semi-bold: [object Object];
  --emt-ui-text-xs-medium: [object Object];
  --emt-ui-text-xs-regular: [object Object];
  --emt-ui-text-xxs-semi-bold: [object Object];
  --emt-body-lg: [object Object];
  --emt-body-md: [object Object];
  --emt-body-sm: [object Object];
  --emt-body-compact-lg: [object Object];
  --emt-body-compact-md: [object Object];
  --emt-body-compact-sm: [object Object];
  --emt-heading-3xl: [object Object];
  --emt-heading-2xl: [object Object];
  --emt-heading-xl: [object Object];
  --emt-heading-lg: [object Object];
  --emt-heading-md: [object Object];
  --emt-heading-sm: [object Object];
  --emt-heading-xs: [object Object];
  --emt-heading-xxs: [object Object];
  --emt-border-width-0: 0px;
  --emt-border-width-50: 0.5px;
  --emt-border-width-100: 1px;
  --emt-border-width-150: 1.5px;
  --emt-border-width-200: 2px;
  --emt-border-width-250: 2.5px;
  --emt-border-width-300: 3px;
  --emt-border-width-400: 4px;
  --emt-border-width-500: 5px;
  --emt-size-25: 1px;
  --emt-size-50: 2px;
  --emt-size-100: 4px;
  --emt-size-200: 8px;
  --emt-size-300: 12px;
  --emt-size-400: 16px;
  --emt-size-500: 20px;
  --emt-size-600: 24px;
  --emt-size-700: 28px;
  --emt-size-800: 32px;
  --emt-size-850: 34px;
  --emt-size-900: 36px;
  --emt-size-1000: 40px;
  --emt-size-1200: 48px;
  --emt-size-1400: 56px;
  --emt-size-1600: 64px;
  --emt-size-1800: 72px;
  --emt-size-2000: 80px;
  --emt-size-2200: 88px;
  --emt-size-2400: 96px;
  --emt-size-2500: 100px;
  --emt-size-2600: 104px;
  --emt-size-2800: 112px;
  --emt-size-3200: 128px;
  --emt-size-8200: 328px;
  --emt-size-10000: 400px;
  --emt-size-10500: 420px;
  --emt-size-14000: 500px;
  --emt-size-15000: 600px;
  --emt-alpha-neutrals-100-a: #0c0d1008;
  --emt-alpha-neutrals-200-a: #0c0d1014;
  --emt-alpha-neutrals-300-a: #0c0d101f;
  --emt-alpha-neutrals-400-a: #0c0d1040;
  --emt-alpha-neutrals-500-a: #0c0d1080;
  --emt-alpha-neutrals-600-a: #0c0d1099;
  --emt-alpha-neutrals-700-a: #0c0d10b3;
  --emt-alpha-dark-neutral-100-a: #ffffff80;
  --emt-alpha-dark-neutral-200-a: #ffffff40;
  --emt-alpha-dark-neutral-300-a: #ffffff26;
  --emt-alpha-dark-neutral-400-a: #ffffff0d;
  --emt-alpha-dark-neutral-500-a: #ffffff08;
  --emt-alpha-dark-neutral-600-a: #ffffff00;
  --emt-alpha-primary-100-a: #0f5fcc99;
  --emt-semantics-negative-100: #F9E9E9;
  --emt-semantics-negative-200: #EDBABC;
  --emt-semantics-negative-300: #E5999C;
  --emt-semantics-negative-400: #D28989;
  --emt-semantics-negative-500: #D14D52;
  --emt-semantics-negative-600: #C62127;
  --emt-semantics-negative-700: #B41E23;
  --emt-semantics-negative-800: #8D171C;
  --emt-semantics-negative-900: #6D1215;
  --emt-semantics-negative-1000: #530E10;
  --emt-semantics-caution-100: #F9F3E8;
  --emt-semantics-caution-200: #F9E2B5;
  --emt-semantics-caution-300: #F7D491;
  --emt-semantics-caution-400: #F3C15F;
  --emt-semantics-caution-500: #DCA434;
  --emt-semantics-caution-600: #B2801C;
  --emt-semantics-caution-700: #A8730B;
  --emt-semantics-caution-800: #936409;
  --emt-semantics-caution-900: #6C4906;
  --emt-semantics-caution-1000: #4F3503;
  --emt-semantics-positive-100: #EDF8F4;
  --emt-semantics-positive-200: #C8EADC;
  --emt-semantics-positive-300: #AEE0CB;
  --emt-semantics-positive-400: #89D2B3;
  --emt-semantics-positive-500: #72C9A5;
  --emt-semantics-positive-600: #4FBC8E;
  --emt-semantics-positive-700: #48AB81;
  --emt-semantics-positive-800: #388565;
  --emt-semantics-positive-900: #2B674E;
  --emt-semantics-positive-1000: #214F3C;
  --emt-semantics-informative-100: #eaf0f7;
  --emt-semantics-informative-200: #bfcfe6;
  --emt-semantics-informative-300: #a0b8d9;
  --emt-semantics-informative-400: #89ABD2;
  --emt-semantics-informative-500: #5984bd;
  --emt-semantics-informative-600: #3065ad;
  --emt-semantics-informative-700: #2c5c9d;
  --emt-semantics-informative-800: #22487b;
  --emt-semantics-informative-900: #1a385f;
  --emt-semantics-informative-1000: #142a49;
  --emt-supporting-color3-100: #E7F5E7;
  --emt-supporting-color3-200: #CFECCF;
  --emt-supporting-color3-300: #91D291;
  --emt-supporting-color3-400: #5FBE5F;
  --emt-supporting-color3-500: #40B140;
  --emt-supporting-color3-600: #109E10;
  --emt-supporting-color3-700: #0F900F;
  --emt-supporting-color3-800: #0B700B;
  --emt-supporting-color3-900: #095709;
  --emt-supporting-color3-1000: #074207;
  --emt-supporting-color4-100: #f2effe;
  --emt-supporting-color4-200: #d7ccfc;
  --emt-supporting-color4-300: #c4b4fb;
  --emt-supporting-color4-400: #a992f9;
  --emt-supporting-color4-500: #997df8;
  --emt-supporting-color4-600: #7f5cf6;
  --emt-supporting-color4-700: #7454e0;
  --emt-supporting-color4-800: #5a41af;
  --emt-supporting-color4-900: #463387;
  --emt-supporting-color4-1000: #352767;
  --emt-body-strong-sm-medium: [object Object];
  --emt-body-strong-sm-compact-medium: [object Object];
  --emt-body-strong-md-medium: [object Object];
  --emt-body-strong-md-semi-bold: [object Object];
  --emt-body-strong-md-compact-bold: [object Object];
  --emt-body-strong-md-compact-medium: [object Object];
  --emt-body-strong-md-bold: [object Object];
  --emt-body-strong-lg-medium: [object Object];
  --emt-body-strong-lg-compact-semi-bold: [object Object];
  --emt-body-strong-lg-compact-medium: [object Object];
  --emt-display-4xl: [object Object];
  --emt-token-set-order-0: core;
  --emt-token-set-order-1: light;
}
